import React, { Component } from 'react'

class LinkedIn extends Component {

  render() {
    const { inverse, transparent, ...otherProps } = this.props
    return <svg
      x="0px"
      y="0px"
      viewBox="0 0 54.619999 54.959999"
      width="54.619999"
      height="54.959999"
      {...otherProps}>
      <g transform="translate(-274.49,-119.14)">
        <path
          d="m 274.49,123.08 c 0,-2.17 1.81,-3.94 4.04,-3.94 l 46.54,0 c 2.23,0 4.04,1.76 4.04,3.94 l 0,47.08 c 0,2.17 -1.81,3.94 -4.04,3.94 l -46.54,0 c -2.23,0 -4.04,-1.76 -4.04,-3.94 l 0,-47.08 0,0 z"
          style={{ fill: transparent ? 'transparent' : inverse ? '#114454' : '#6e7071' }}
        />
        <path
          d="m 291.04,165.14 0,-24.81 -8.25,0 0,24.81 8.25,0 0,0 z m -4.12,-28.2 c 2.88,0 4.67,-1.91 4.67,-4.29 -0.05,-2.43 -1.79,-4.29 -4.61,-4.29 -2.82,0 -4.67,1.85 -4.67,4.29 0,2.38 1.79,4.29 4.56,4.29 l 0.05,0 0,0 z"
          style={{ clipRule: 'evenodd', fill: transparent ? '#114454' : inverse ? 'white' : 'white', fillRule: 'evenodd' }}
        />
        <path
          d="m 295.6,165.14 8.25,0 0,-13.86 c 0,-0.74 0.05,-1.48 0.27,-2.01 0.6,-1.48 1.95,-3.02 4.23,-3.02 2.99,0 4.18,2.28 4.18,5.61 l 0,13.28 8.25,0 0,-14.23 c 0,-7.62 -4.07,-11.17 -9.5,-11.17 -4.45,0 -6.4,2.49 -7.49,4.18 l 0.05,0 0,-3.6 -8.25,0 c 0.12,2.34 0.01,24.82 0.01,24.82 l 0,0 z"
          style={{ clipRule: 'evenodd', fill: transparent ? '#114454' : inverse ? 'white' : 'white', fillRule: 'evenodd' }}
        />
      </g>
    </svg>
  }

}

export default LinkedIn
