import { withStyles } from 'react-with-styles'
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet'

import AphroditeInterface from 'react-with-styles-interface-aphrodite'

import Theme from './Theme'

ThemedStyleSheet.registerTheme(Theme)
ThemedStyleSheet.registerInterface(AphroditeInterface)

export { withStyles, ThemedStyleSheet }
