import './App.sass'

import { Grid, Row, Col } from 'react-flexbox-grid'
import { Link, Element } from 'react-scroll'
import React, { Component } from 'react'

import LinkedIn from './LinkedIn'
import Modal from './Modal'

class App extends Component {
  state = {}

  render() {
    const { showModal } = this.state

    return (
      <div
        style={{
          color: '#124552',
          textTransform: 'uppercase',
          letterSpacing: 1,
          fontSize: 14
        }}>
        {showModal && (
          <Modal
            headerTitle='Mentions légales'
            onClose={() => this.setState({ showModal: false })}>
            <div style={{ textTransform: 'none' }}>
              <p>
                Site édité par Harper Avocats, Association d'Avocats à
                Responsabilité Professionnelle Individuelle, enregistrée à
                l’INSEE sous le n° 881 029 748, ayant son siège social 4ter, rue
                du Bouloi – 75001 Paris ; Téléphone : +33 1 45 08 02 79.
              </p>
              <p>Le directeur de la publication est Stéphanie Leroy.</p>
              <p>
                Le site est hébergé par Gitlab Inc, 268 Bush Street Suite 305
                San Francisco, CA 94104 United States, +1-415-829-2854
              </p>
            </div>
          </Modal>
        )}
        <Element name='header' className='element'>
          <header style={{ padding: 16 }}>
            <Grid className='container-header'>
              <Row between='xs' middle='xs'>
                {[
                  'Le Cabinet',
                  'Expertises',
                  <img src='/logo.svg' alt='Logo' width='118' />,
                  'Avocats',
                  'Contact'
                ].map((navigationItem, index) => (
                  <Col key={`navigation-item-${index}`}>
                    {index === 2 ? (
                      <div>{navigationItem}</div>
                    ) : (
                      <Link
                        to={navigationItem
                          .split(' ')
                          .join('-')
                          .toLowerCase()}
                        smooth={true}
                        duration={500}
                        className={index === 2 ? '' : 'hidden-xs'}>
                        {navigationItem}
                      </Link>
                    )}
                  </Col>
                ))}
              </Row>
            </Grid>
          </header>
        </Element>

        <Element name='le-cabinet' className='element'>
          <section>
            <img
              src='/cover.jpg'
              alt='Couverture'
              style={{ margin: 'auto', display: 'block', maxWidth: '100%' }}
            />
            <h1 style={{
              backgroundColor: '#164757',
              color: 'white',
              paddingTop: 8,
              paddingBottom: 8,
              textAlign: 'center'
            }}>Harper Avocats</h1>
            <Grid>
              <Row center='xs'>
                <Col style={{ maxWidth: 840, textTransform: 'none' }}>
                  <p className='lead'>
                    Le cabinet Harper Avocats accompagne les entreprises, start-ups
                    et créateurs dans leurs problématiques de droit des affaires&nbsp;:
                    droit social, droit économique et propriété intellectuelle.
                  </p>
                </Col>
              </Row>
            </Grid>
          </section>
        </Element>

        <Element name='expertises' className='element'>
          <section
            style={{
              paddingTop: 60,
              paddingBottom: 60,
              textAlign: 'center'
            }}>
            <Grid>
              <h2>Expertises</h2>
              <Row
                center='xs'
                className='skills'>
                <Col xs={12} md={6}>
                  <div className='features'>
                    {[
                      'Droit du travail',
                      'Protection sociale'
                    ].map((name, index) => (
                      <div key={`feature-${index}`}>
                        <h3 style={{ textAlign: 'center' }}>
                          {name}
                        </h3>
                      </div>
                    ))}
                  </div>
                  <div
                    style={{
                      textAlign: 'left',
                      textTransform: 'none',
                      color: '#777',
                      padding: 10
                    }}
                  >
                    <p>
                      Rédaction de contrat de travail, procédure de licenciement, transaction, rupture conventionnelle
                    </p>
                    <p>
                      Rédaction d’accord d’entreprise ou de charte (temps de travail, télétravail, déconnexion, parental act) et de procédures de recueil des alertes
                    </p>
                    <p>
                      Gestion des conflits au travail (harcèlement, discrimination, burn out, assistance pour les litiges AT/MP et FIE)
                    </p>
                    <p>
                      Gestion des IRP : élections du CSE, NAO (accord de méthode), règlement intérieur et accord d’adaptation du CSE
                    </p>
                    <p>
                      Mise en œuvre de licenciement collectif pour motif économique (PSE, PDV)
                    </p>
                    <p>
                      Contestation de redressement URSSAF
                    </p>
                    <p>
                      Statuts spécifiques : intermittents du spectacle, journalistes, salariés portés et cadres dirigeants
                    </p>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className='features'>
                    {[
                      'Propriété intellectuelle',
                      'Droit économique'
                    ].map((name, index) => (
                      <div key={`feature-${index}`}>
                        <h3 style={{ textAlign: 'center' }}>
                          {name}
                        </h3>
                      </div>
                    ))}
                  </div>
                  <div
                    style={{
                      textAlign: 'left',
                      textTransform: 'none',
                      color: '#777',
                      padding: 10
                    }}
                  >
                    <p>
                      Contrats (rédaction, négociation et management) - Contentieux commercial (rupture des relations, concurrence déloyale, parasitisme)
                    </p>
                    <p>
                      Droit d’auteur et droit des marques (valorisation, sécurisation, exploitation et défense contre la contrefaçon)
                    </p>
                    <p>
                      Numérique, nouvelles technologies et data (solutions SaaS, applications digitales, logiciels, innovations, données à caractère personnel : audit RGPD / DPO)
                    </p>
                    <p>
                      Distribution et consommation (publicité, commerce électronique, marketplaces, réseaux de distribution : sélective, exclusive, franchise)
                    </p>
                  </div>
                </Col>
              </Row>

              <h2 style={{ marginTop: 60 }}>Secteurs d’activité</h2>
              <Row
                center='xs'
                style={{
                  textAlign: 'center',
                  textTransform: 'none',
                  color: '#777'
                }}>
                <p className='paragraph-responsive' style={{ maxWidth: 570 }}>
                  digital, communication, media   /   commerce, distribution   /   luxe, mode   /   architecture d’intérieur, design   /   banque   /   portage salarial
                </p>
              </Row>
            </Grid>
          </section>
        </Element>

        <Element name='avocats' className='element'>
          <section style={{ position: 'relative', backgroundColor: '#f0f0f0' }}>

            <h2 style={{ textAlign: 'center', paddingTop: 60 }}>
              Avocats associés
            </h2>

            <Grid>
              <Row>
                <Col xs={12} md={6}>
                  <div className='team-member'>
                    <h2>
                      Stéphanie Leroy
                    </h2>
                    <div>
                      <div className='avatar-container'>
                        <img
                          src='stephanie-leroy-v3.jpg'
                          alt='Stéphanie Leroy'
                          className='avatar'
                        />
                      </div>
                      <div>
                        <p style={{ textTransform: 'none' }}>
                          Stéphanie a fondé Harper Avocats en 2019, après plus de 11 années passées au sein du cabinet d’affaires DDG (Deprez Guignot & Associés), où elle était associée au sein du Pôle Droit Social.
                        </p>
                        <a
                          href='https://www.linkedin.com/in/stéphanie-leroy-a5b42859'
                          target='_blank'
                          rel='noopener noreferrer'>
                          <LinkedIn style={{ marginTop: 6, width: 30, height: 30 }} />
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className='team-member'>
                    <h2>
                      Guillaume Deglaire
                    </h2>
                    <div>
                      <div className='avatar-container'>
                        <img
                          src='guillaume-deglaire-v2.jpg'
                          alt='Guillaume Deglaire'
                          className='avatar'
                        />
                      </div>
                      <div>
                        <p style={{ textTransform: 'none' }}>
                          Guillaume a rejoint Harper Avocats après 18 années passées au sein du cabinet d’affaires DDG (Deprez Guignot & Associés) où il était associé au sein des Pôles IP-IT & Média et Droit économique.
                        </p>
                        <a
                          href='https://www.linkedin.com/in/guillaume-deglaire-29a00113a'
                          target='_blank'
                          rel='noopener noreferrer'>
                          <LinkedIn style={{ marginTop: 6, width: 30, height: 30 }} />
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Grid>

            <h2 style={{ textAlign: 'center', paddingTop: 60 }}>
              Avocats collaborateurs
            </h2>

            <Grid>
              <Row>
                <Col xs={12} md={6}>
                  <div className='team-member'>
                    <div>
                      <div className='avatar-container'>
                        <img
                          src='lucie-brebion.jpg'
                          alt='Lucie Brebion'
                          className='avatar avatar-small'
                        />
                        <a
                          href='https://www.linkedin.com/in/lucie-brebion-7abb8a113'
                          target='_blank'
                          rel='noopener noreferrer'>
                          <LinkedIn style={{ marginTop: 6, width: 30, height: 30 }} />
                        </a>
                      </div>
                      <div>
                        <h3>
                          Lucie Brebion
                        </h3>
                        <p style={{ textTransform: 'none', fontSize: 13 }}>
                          Lucie est membre de l’équipe droit social d’Harper Avocats depuis 2020, après 2 années passées en tant que collaboratrice au sein d’un cabinet d’affaires parisien. Avant de rejoindre la profession d’avocat, Lucie a également exercé pendant 1 an et demi les fonctions de juriste en droit social. Elle est titulaire d’un Master 2 en droit social de l’Université Lille 2.
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className='team-member'>
                    <div>
                      <div className='avatar-container'>
                        <img
                          src='jossy-mendes.jpg'
                          alt='Jossy Mendes'
                          className='avatar avatar-small'
                        />
                        <a
                          href='https://www.linkedin.com/in/jossy-mendes-344a25109'
                          target='_blank'
                          rel='noopener noreferrer'>
                          <LinkedIn style={{ marginTop: 6, width: 30, height: 30 }} />
                        </a>
                      </div>
                      <div>
                        <h3>
                          Jossy Mendes
                        </h3>
                        <p style={{ textTransform: 'none', fontSize: 13 }}>
                          Jossy est membre de l’équipe droit social d’Harper Avocats depuis sa prestation de serment en 2022. Avant de rejoindre la profession d’avocat, Jossy a eu plusieurs expériences professionnelles en tant que juriste en droit social en entreprise et au sein de cabinets d’avocats. Il est titulaire d’un Master 2 en droit social et mobilité internationale des travailleurs, de l’Institut d'Etudes du Travail de Lyon.
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Grid>

            <Grid>
              <h2 style={{ textAlign: 'center', paddingTop: 60 }}>
                Partenaires
              </h2>
              <Row
                center='xs'
                style={{
                  textAlign: 'center',
                  textTransform: 'none',
                  color: '#777',
                  paddingBottom: 60
                }}>
                <p className='paragraph-responsive' style={{ maxWidth: 820 }}>
                  Harper Avocats est en mesure de fournir des conseils à 360° sur tous les aspects du droit des affaires, en partenariat avec des avocats spécialisés en droit des sociétés et en droit fiscal ainsi que des conseils en propriété industrielle.
                </p>
                <p className='paragraph-responsive' style={{ maxWidth: 820 }}>
                  Harper Avocats propose également des solutions de vote électronique, fournies par un partenaire digital, pour la signature des accords d’entreprise et des élections professionnelles ainsi que d’assistance pour les questions de mobilité internationale.
                </p>
              </Row>
            </Grid>
          </section>
        </Element>

        <Element name='contact' className='element'>
          <section
            style={{
              paddingTop: 60,
              paddingBottom: 40,
              textAlign: 'center'
            }}>
            <Grid>
              <div>
                <h2>Contact</h2>
                <p style={{ lineHeight: '26px', marginTop: 20 }}>
                  4TER, RUE DU BOULOI – 75001 PARIS
                  <br />
                  contact@harperavocats.fr
                  <br />
                  T +33 1 45 08 02 79
                  <br />
                  Fax +33 1 45 08 95 43
                </p>
                <a
                  href='https://www.linkedin.com/company/harper-avocats'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <LinkedIn
                    style={{ width: 36, height: 36 }}
                    transparent
                  />
                </a>
              </div>
            </Grid>
          </section>
        </Element>

        <footer
          style={{
            backgroundColor: '#F7F7F7',
            paddingTop: 16,
            paddingBottom: 16
          }}>
          <Grid>
            <ul
              style={{
                paddingLeft: 0,
                listStyle: 'none',
                textAlign: 'center',
                marginBottom: 0
              }}>
              <li style={{ display: 'inline-block' }}>
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: 300,
                    borderRight: '1px solid #154559',
                    paddingRight: 10,
                    lineHeight: '10px'
                  }}>
                  <a
                    href=''
                    onClick={event => {
                      event.preventDefault()
                      this.setState({ showModal: true })
                    }}>
                    Mentions légales
                  </a>
                </div>
              </li>
              <li style={{ display: 'inline-block' }}>
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: 300,
                    borderRight: '1px solid #154559',
                    paddingLeft: 10,
                    paddingRight: 10,
                    lineHeight: '10px'
                  }}>
                  <a
                    href='mailto:contact@harperavocats.fr'
                    target='_blank'
                    rel='noopener noreferrer'>
                    Contact
                  </a>
                </div>
              </li>
              <li style={{ display: 'inline-block' }}>
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: 300,
                    paddingLeft: 10,
                    paddingRight: 10
                  }}>
                  {`© ${new Date().getFullYear()} Tous droits réservés`}
                </div>
              </li>
              <li style={{ display: 'inline-block' }}>
                <div style={{ paddingLeft: 10 }}>
                  <a
                    href='https://www.linkedin.com/company/harper-avocats'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <LinkedIn
                      style={{ marginTop: -4, width: 17, height: 17 }}
                      inverse
                    />
                  </a>
                </div>
              </li>
            </ul>
          </Grid>
        </footer>
      </div>
    )
  }
}

export default App
