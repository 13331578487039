import { css } from 'react-with-styles'
import React from 'react'

import { withStyles } from './withStyles'

const Close = props => (
  <img src='/close.svg' alt='Close' width='36' height='36' {...props} />
)

class Modal extends React.Component {
  componentDidMount() {
    window.addEventListener('keydown', this.listenForEscape, true)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.listenForEscape, true)
  }

  listenForEscape = ({ key }) => {
    const { onClose } = this.props
    onClose && key === 'Escape' && onClose()
  }

  render() {
    const {
      onClose,
      headerTitle,
      headerDescription,
      styles,
      children,
      ...otherProps
    } = this.props
    const showHeader = headerTitle || headerDescription

    return (
      <div {...css(styles.container)}>
        <div {...css(styles.backdrop)} onClick={() => onClose && onClose()} />
        <div {...css(styles.content)} {...otherProps} css={null}>
          {showHeader && (
            <div {...css(styles.header)}>
              {headerTitle && <h2>{headerTitle}</h2>}
              {headerDescription && (
                <div {...css(styles.headerDescription)}>
                  {headerDescription}
                </div>
              )}
            </div>
          )}
          {children}
          <Close
            onClick={() => onClose && onClose()}
            {...css(
              styles.closeButton,
              showHeader && styles.closeButton_withHeader
            )}
          />
        </div>
      </div>
    )
  }
}

export default withStyles(() => {
  return {
    container: {
      position: 'fixed',
      width: '100%',
      heigth: '100%',
      top: 0,
      left: 0,
      zIndex: 1
    },
    content: {
      position: 'relative',
      width: 800,
      maxWidth: '95%',
      margin: '215px auto',
      paddingTop: 60,
      paddingRight: 80,
      paddingBottom: 50,
      paddingLeft: 80,
      backgroundColor: 'white',
      border: '22px solid #114454',
      maxHeight: '90vh',
      animationName: [
        {
          from: {
            transform: 'translateY(-200px)'
          },
          to: {
            transform: 'translateY(0)'
          }
        }
      ],
      animationDuration: '300ms',
      '@media (max-width: 800px)': {
        paddingRight: 20,
        paddingLeft: 20,
        marginTop: 40,
        overflow: 'scroll'
      }
    },
    backdrop: {
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      opacity: 0.7,
      backgroundColor: '#114454',
      animationName: [
        {
          from: {
            opacity: 0
          },
          to: {
            opacity: 0.4
          }
        }
      ],
      animationDuration: '300ms'
    },
    closeButton: {
      cursor: 'pointer',
      position: 'absolute',
      top: 30,
      right: 30,
      ':hover': {}
    },
    closeButton_withHeader: {},
    header: {
      marginTop: -10,
      marginLeft: -10,
      marginRight: -10,
      padding: 20,
      textAlign: 'center'
    },
    headerDescription: {
      marginTop: -10
    }
  }
})(Modal)
